(function () {
  'use strict';

  class CanvasZoomCtrl {
    constructor($mdDialog, CanvasZoom) {
      let vm = this;
      vm.$mdDialog = $mdDialog;
      vm.resource = CanvasZoom.getObj();
    }
    cancel() {
      let vm = this;
      vm.$mdDialog.cancel();
    }
  }

  /**
   * @ngdoc object
   * @name components.controller:CanvasZoomCtrl
   *
   * @description
   *
   */
  angular
    .module('components')
    .controller('CanvasZoomCtrl', CanvasZoomCtrl);
}());
